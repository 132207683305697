import { graphql } from "gatsby"
import { compose } from "recompose"

import BicycleProductList from "../views/BicycleProductList"
import withTransformData from "../utils/withTransformData"

export const query = graphql`
  query BicycleProductList($bicycleSlugs: [String]!, $pageSlug: String!) {
    collection: contentfulCollection(internalTitle: { eq: $pageSlug }) {
      seoTitle
      internalTitle
      seoDescription {
        seoDescription
      }
      seoBackgroundImage {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      seoContentBlock {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            fluid(maxWidth: 1440, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      seoContentBlockVideo{
        seoContentBlockVideo
      }
      banner {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      mobileBanner {
        fluid(maxWidth: 768, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bannerSlug
      enableBoxQuiz
      boxQuizPosition
      boxQuizImage
    }
    colors: allContentfulColorFamily {
      edges {
        node {
          internalTitle
          name
          hexColor
          product_color {
            internalTitle
          }
        }
      }
    }
    bicycles: allContentfulBicycleModel(
      filter: { internalTitle: { in: $bicycleSlugs }, hidden: { ne: true } }
    ) {
      edges {
        node {
          bikeImages {
            internal {
              content
            }
          }
          bodyFitMaxHeight
          bodyFitMaxWeight
          bodyFitMinHeight
          formattedTitle
          gender
          internalTitle
          bestSeller
          speeds {
            internalTitle
            speed
            wheelSize
            armLength
            legLength
            primaryUse
            rideFrequency
            ridePreference
            ridingPace
            ridingSurface
            seatPreference
            uphillFrequency
            bodyPain
            maxDistance
            minDistance
            variants {
              shopifyId
              color {
                internalTitle
                hexCode
                name
              }
              isNew
              forSale
              productListingImage {
                fluid(maxWidth: 1000, quality: 85) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
            }
          }
          style
          terrain
          title
          seoTitle
          type
        }
      }
    }
  }
`

export default compose(withTransformData)(BicycleProductList)
